body {
    background-color: var(--background-color);
    background-image: url(../assets/background.svg);
    background-size: 159px 171px;

    @media (max-width: 767px) {
        background-image: none;
    }
}

.page-header,
.nav-main,
.footer {
    background-size: 100% auto;
    background-image: url(../assets/background-head-1x.jpg);
    background-image: image-set(
            url(../assets/background-head-1x.webp) 1x type("image/webp"),
            url(../assets/background-head-1.5x.webp) 1.5x type("image/webp"),
            url(../assets/background-head-2x.webp) 2x type("image/webp")
    );

    @media (max-width: 480px) {
        background-image: image-set(
            url(../assets/background-head-1x.webp) type("image/webp"),
            url(../assets/background-head-1x.jpg) type("image/jpeg")
        );
    }
}

.nav-main {
    background-position: 0 -80px;
}
