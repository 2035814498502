@media (max-width: 979px) {
    #comment-add-link + .comment-form {
        background: transparent none;
    }
}


@media (max-width: 640px) {
    .nav-main-links {
        background-color: #1D2A4F;
    }
}
